.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
  display: none;
}

.rdrCalendarWrapper {
  background-color: #E6ECF8;
}

.rdrMonth {
  padding: 0;
}

.date_range_container {
  overflow: hidden;
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  padding: 15px;
  display: 'flex';
  flex-direction: 'column';
  gap: '1rem';
  background-color: #E6ECF8;
  border: 1px solid #1466FF;
  border-radius: 10px;
  z-index: 2000;
}

.select_info {
  border-top: 1px solid #ACB6C9;
  margin-top: 15px;
  padding-top: 15px;
}

.selected_date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-time-picker__wrapper {
  border-radius: 8px;
  border: 1px solid #ACB6C9;
}

.apply_button,
.reset_button {
  margin-top: 15px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background: #1466FF;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  transition: .3s;
  &:hover {
    background: #185FE3;
    box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
  }

  &:active {
    background: #0C52D5;
    box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    background: #5C5C5C;
    box-shadow: none;
  }
}

.reset_button {
  background: rgba(217, 230, 255, 0.45);
  border: 1px solid #1466FF;
  color: #1466FF;

  &:hover {
    background: rgba(253, 253, 254, 0.6);
  }

  &:active {
    background: #E2EBFB;
  }
}