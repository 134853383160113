.recognition_container {
    display: flex;
    padding-top: 100px;
    padding-bottom: 50px;
}

.recognition_body {
    //min-height: 50vh;
    flex: 1 0 60%;
    background: rgba(20, 102, 255, 0.62);
    border-radius: 20px;
    margin-right: 10px;
}

.birds {
    flex: 1 0 35%;
    margin-left: 10px;
    position: relative;
}

@media (max-width: 1024px) {
    .birds {
        display: none;
    }

    .recognition_body {
        margin-right: 0;
    }
}

.recognition_model {
    padding: 30px 0;
    display: flex;
    align-items: center;
}

.recognotion_tittle {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #1466FF;
    border-radius: 0 15px 15px 0;
}

@media (max-width: 768px) {
    .recognotion_tittle {
        padding: 10px 20px 10px 10px;
        font-size: 16px;
    }
}

.dragDropAudio {
    margin: 20px 20px 0;
    display: flex;
    flex-direction: column;
}

.dragDrop {
    flex: 1 0 70%;
    width: 100%;
    background: rgba(236, 243, 255, 0.62);
    border-radius: 20px 20px 0 0;

}

.preloader {
    img {
        animation: rot infinite 1s linear 0s;
    }

    .drop_area {
        text-align: center;
    }
}

@keyframes rot {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.drop_area {
    display: flex;
    flex-direction: column;
    min-height: 205px;
    max-height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 26.4382px;
    line-height: 31px;
    color: rgba(40, 116, 255, 0.56);
    padding: 0 20px;
}

.audio {
    flex: 1 0 30%;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.upload__buttons {
    display: flex;
    margin: 0 1.5%;
    padding: 25px 0 15px 0;
}

.file_upload {
    flex: 1 0 40%;
    margin: 0 1.5%;
    width: 100%;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(217, 230, 255, 0.45);
    border: 1px solid #1466FF;
    border-radius: 14px;
    color: #1466FF;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 480px) {
    .file_upload {
        font-size: 18px;
        text-align: center;
    }
}

input[type="file"] {
    display: none
}

.post {
    flex: 1 0 40%;
    margin: 0 1.5%;

    button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(217, 230, 255, 0.45);
        border: 1px solid #1466FF;
        border-radius: 14px;
        color: #1466FF;
        font-size: 24px;
        font-weight: 600;
    }
}

@media (max-width: 480px) {
    .post button {
        font-size: 18px;
    }
}

.file_upload:hover,
.post button:hover {
    background: rgba(253, 253, 254, 0.6);
}

.file_upload:active,
.post button:active {
    background: #E2EBFB;
}

.post button:disabled {
    background: rgba(216, 216, 216, 0.45);
    border: 1px solid #25282B;
    color: #25282B;
}


@keyframes display {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    10% {
        transform: translateX(0);
        opacity: 1;
    }

    20% {
        transform: translateY(0);
        opacity: 1;
    }

    30% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(-200px);
        opacity: 0;
    }
}

.pic_ctn,
.pic_ctn_no_anim {
    display: none;
}

.pic_ctn>img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    animation: display 10s infinite;
    border-radius: 20px;
    max-width: 100%;
    height: 220px;

    &:nth-child(2) {
        animation-delay: 2s;
    }

    &:nth-child(3) {
        animation-delay: 4s;
    }

    &:nth-child(4) {
        animation-delay: 6s;
    }

    &:nth-child(5) {
        animation-delay: 8s;
    }
}

.pic_ctn_no_anim>img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 20px;
    max-width: 100%;
    height: 220px;
}

@media (max-width: 768px) {

    .pic_ctn,
    .pic_ctn_no_anim {
        display: block;
        position: relative;
        height: 220px;
        margin-bottom: 10px;
    }
}
