.header_container {
    margin-top: -17px;
    align-self: start;
    display: flex;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .header_container {
        display: none;
    }
}

.menu_container {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    margin-right: 10px;
    position: relative;
}

@media (max-width: 768px) {
    .menu_container {
        min-width: 100px;
    }
}

.menu_vis {
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1466FF;
    color: #FFFFFF;
    border-radius: 0;
}

.menu {
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1466FF;
    color: #FFFFFF;
    border-radius: 0px 0px 9px 9px;
}

.menu_drop_vis {
    width: 100%;
    position: absolute;
    top: 100%;
    background: rgba(9, 78, 207, 0.69);
    border-radius: 0px 0px 9px 9px;
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
        color: #FFFFFF;

        &:hover {
            background: rgb(9, 78, 207);
        }

        &:last-child:hover {
            border-radius: 0px 0px 9px 9px;
        }
    }
}

.menu_drop {
    display: none;
}

.drop_item {
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 15px 0;
}

.arrow {
    padding-left: 10px;
    margin-top: -5px;
    display: inline
}

.arrow_vis {
    padding-left: 10px;
    margin-top: 5px;
    display: inline
}

.arrow_up {
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(225deg);
}

.arrow_down {
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
}

.userHi {
    margin-right: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    background-color: #1466FF;
    color: #FFFFFF;
    border-radius: 0px 0px 9px 9px;
    min-width: 250px;
}

.user_logo {
    margin: 5px 5px 0 5px;
    max-height: 42px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.user_settings {
    cursor: pointer;
    margin-bottom: -4px;

    .setting_icon {
        color: #FFFFFF;
        transition: .3s;
        font-size: 30px;

        &:hover {
            transform: rotate(90deg);
        }
    }

}

@media (min-width: 769px) {
    .mobile__menu {
        display: none;
    }
}

.burger__menu_invis {
    display: none;
}

.burger__menu i {
    font-size: 40px;
    color: #1466FF;
}

.bar__background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.side__bar,
.side__bar_invis {
    position: absolute;
    top: 0;
    right: -250px;
    background: rgba(9, 78, 207, 0.69);
    backdrop-filter: blur(2px);
    color: #FFFFFF;
    font-weight: 500;
    font-size: 20px;
    border-radius: 0 0 0 10px;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    transform: translateX(-250px);
    transition: all .3s linear;

    a {
        text-decoration: none;
        color: #FFFFFF;
    }

    i {
        padding: 10px;
        align-self: end;
        font-size: 40px;
    }
}

.side__bar_invis {
    right: 0;
    transform: translateX(250px);
}

.side__user {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    background: #0047CA;
}