.paginator {    
    padding: 60px 0;
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    button {
        padding: 5px 15px;
        background-color:#1466FF;
        color: #FFFFFF;
        font-size: 20px;
        border-radius: 5px;
        box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
        &:hover {
            background: #185FE3;
            box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
        }
        &:active {
            background: #0C52D5;
            box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
        }
        &:disabled {
            background: #5C5C5C;
            box-shadow: none;
        }
    }
}
.page_number, .arrow_paginator {
    cursor: pointer;
    color: #1466FF;
    font-size: 24px;
    margin: 0 5px;
    transition: .3s;
    &:hover {
        color: #0a3ea0; 
        transform: scale(1.2);
    }
}
.page_current {
    cursor: pointer;
    color: #012d80;
    font-size: 32px;
    margin: 0 5px;
}