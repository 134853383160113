.table__buttons, .table__header {
    display: flex;
    font-size: 15px;
    text-transform: capitalize;
    color: #DAEDFF;
}
@media (max-width: 768px) {
    .table__buttons, .table__header {
        font-size: 13px;
        font-weight: 600;
        justify-content: center;
    }
}
.buttons__container {
    display: flex;
    border-radius: 10px;
    padding: 5px 10px;
    background: #1466FF;
    color: #ACB6C9;
    align-items: center;
    div {
        padding: 0 30px;
        cursor: pointer;
        transition: .3s;
        display: flex;
        align-items: center;
        gap: 5px;
        &:hover {
            color: #FFFFFF;
        }
    }
    a {
        color: #ACB6C9;
    }
}
@media (max-width: 768px) {
    .buttons__container {
        padding: 0;
        align-items: center;
        margin-bottom: 20px;
        div {
            padding: 5px 15px;
        }
    }
}
.table__add, .button__approve {
    cursor: pointer;
    display: inline-flex;
    margin-top: 30px;
    padding: 10px 50px;
    background: #1466FF;
    color: #DAEDFF;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    gap: 15px;
    box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    transition: .1s;

        &:hover {
            background: #185FE3;
            box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
        }
        &:active {
            background: #0C52D5;
            box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
        }
        &:disabled {
            background: #5C5C5C;
            box-shadow: none;
        }
}
@media (max-width: 768px) {
    .table__add {
        display: none;
    }
}