.wrapper {
    padding: 0 20px;
    position: relative;
    z-index: 5;
    min-height: 100vh;
}
@media (max-width: 768px) {
    .wrapper {
        padding: 0 15px;
    }
}
.upperCloud {
    background-image: url('../../assets/main/mainbg.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 95%;
    width: 8290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    overflow: hidden;
}

.uuderCloud {
    background-image: url('../../assets/mail/mainbg2.png');
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25%;
    width: 11290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    animation-direction: reverse;
    overflow: hidden;
}

@keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

.tablePage__container {
    padding-top: 100px;
    position: relative;
    min-height: 100%;
    z-index: 10;
    a {
        text-decoration: none;
    }
}

.tittle {
    display: inline-block;
    margin: 20px -40px;
    //margin-left: -40px;
    padding: 10px 80px 10px 180px;
    font-size: 34px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #1466FF;
    border-radius: 0px 20px 20px 0px;
}
@media (max-width: 768px) {
    .tittle {
        display: none;
    }
}

.table__header {
    display: flex;
    font-size: 15px;
    text-transform: capitalize;
    color: #DAEDFF;
}
@media (max-width: 768px) {
    .table__buttons, .table__header {
        font-size: 13px;
        font-weight: 600;
        justify-content: center;
    }
}
.buttons__container {
    display: flex;
    border-radius: 10px;
    padding: 5px 10px;
    background: #1466FF;
    color: #ACB6C9;
    align-items: center;
    div {
        padding: 0 30px;
        cursor: pointer;
        transition: .3s;
        display: flex;
        align-items: center;
        gap: 5px;
        &:hover {
            color: #FFFFFF;
        }
    }
}
@media (max-width: 768px) {
    .buttons__container {
        padding: 0;
        align-items: center;

        div {
            padding: 5px 15px;
        }
    }
}
.table__add, .button__approve {
    cursor: pointer;
    display: inline-flex;
    margin-top: 30px;
    padding: 10px 50px;
    background: #1466FF;
    color: #DAEDFF;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    gap: 15px;
    box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    transition: .1s;

        &:hover {
            background: #185FE3;
            box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
        }
        &:active {
            background: #0C52D5;
            box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
        }
        &:disabled {
            background: #5C5C5C;
            box-shadow: none;
        }
}
@media (max-width: 768px) {
    .table__add {
        display: none;
    }
}

.table__main {
    margin: 0px 20px;
}
@media (max-width: 768px) {
    .table__main {
        margin: 15px 0 0 0;
    }
}
.table__header {
    justify-content: end;
    div {
        border-radius: 5px 5px 0 0;
    }
}


table {
    width: 100%;
    border-collapse: collapse;
}
thead {
    background: #3277F9;
    color: #FFFFFF;
    font-size: 15px;
    text-transform: capitalize;
    text-align: center;
    height: 45px;
}
tbody {
    text-align: center;
    color: #00164E;
}
.raw_of_table {
    td {
        word-break: break-all;
        height: 45px;
        padding: 5px;
    }
    &:nth-child(odd) {
        background: #D9E3F8;
    }
    &:nth-child(even) {
        background: #C9DCFF;
    }
    button {
        font-size: 15px;
        min-width: 80px;
        border-radius: 15px;
        text-transform: uppercase;
        margin: 0 5px;
        word-break: initial;
    }
    .raw_id {
        min-width: 50px;
    }
} 
@media (max-width: 768px) {
    .table__header {
        display: none;
    }
    .table__content {
        border-radius: 15px;
        overflow: hidden;
    }
    .table {
        display: none;
    }
}
.table__audio {
    margin-top: 3px;
    height: 30px;
    border: 1px solid #203B7E;
    border-radius: 15px;
    background: rgba(70, 122, 255, 0.6);
}

audio::-webkit-media-controls-panel {
    
    background: rgba(70, 122, 255, 0.3);
}

.button__approve {
    padding: 5px 15px;
}

.button__refuse {
    background: rgba(217, 230, 255, 0.45);
    color: rgba(20, 102, 255, 0.8);
    border: 0.5px solid #1466FF;
    border-radius: 20px;
    padding: 4px 14px;
    transition: .3s;
    &:hover {
        background: rgba(253, 253, 254, 0.6);
    }
    &:active {
        background: #1957CC;
        color: #FFFFFF;
    }
}

.preloader_body {
    display: flex;
    justify-content: center;
    .preloader {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.icon_add {
    font-size: 35px !important;
}
.icon_close{
    font-size: 30px !important;
    color: #F13A3A; 
}
.icon_done{
    font-size: 30px !important;
    color: #09D72A; 
}

.birds_recognized,
.dots_column_container {
    position: relative;
    cursor: pointer;
}

.birds_recognized_hidden {
    position: absolute;
    bottom: 100%;
    display: block;
    opacity: 0;
    padding: 10px;
    background-color: #FFFFFF;
    font-size: 14px;
    line-height: 20px;
    word-break: normal;
    z-index: 1000;
    pointer-events: none;
    transition: opacity 0.3s ease;
}
.birds_recognized:hover .birds_recognized_hidden {
    opacity: 1;
    pointer-events: auto; 
}

.status_raw {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.false_status,
.true_status,
.process_status  {
    width: 60px;
    height: 18px;
    font-size: 11px;
    line-height: 16px;
    border-radius: 4px;
}

.false_status {
    background-color: #FCD9D9;
}

.true_status {
    background-color: #D1FADF;
}

.process_status {
    background-color: #FCF6D9;
}
.dots_column_container {
    display: flex;
    align-items: center;
    height: 20px;
    width: 20px;
}

.column_box {
    position: absolute;
    width: 150px;
    bottom: 100%;
    right: 0;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 5px;
    opacity: 0;
    pointer-events: none;
    word-break: initial;

    a {
        color: #000000;
        padding: 10px;
        &:hover {
            color: #1466FF;
        }
    }
    a:not(:last-child) {
        border-bottom: 1px solid #E1E6EF;
    }
}

.dots_column_container:hover .column_box {
    opacity: 1;
    pointer-events: auto; 
}

.buttons_box {
    padding: 25px 0 15px 0;
}

.upload__buttons {
    display: flex;
}

.file_upload {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(217, 230, 255, 0.45);
    border: 1px solid #1466FF;
    border-radius: 14px;
    color: #1466FF;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 480px) {
    .file_upload {
        font-size: 18px;
        text-align: center;
    }
}

input[type="file"] {
    display: none
}

.post {
    flex: 1 0 40%;
    margin: 0 1.5%;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(217, 230, 255, 0.45);
        border: 1px solid #1466FF;
        border-radius: 14px;
        color: #1466FF;
        font-size: 16px;
        font-weight: 600;
    }
}

@media (max-width: 480px) {
    .post button {
        font-size: 18px;
    }
}

.file_upload:hover,
.post button:hover {
    background: rgba(253, 253, 254, 0.6);
}

.file_upload:active,
.post button:active {
    background: #E2EBFB;
}

.buttons_box {
    display: flex;
    justify-content: space-between;
}

.filter_buttons {
    display: flex;
    gap: 10px;
}


.filter_but {
    cursor: pointer;
    position: relative;
    padding: 10px 15px; 
    border: 1px solid #1466FF;
    border-radius: 10px;
    color: #1466FF;
    background-color: #E6ECF8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show_but {
    position: relative;
}