.mail {
    position: relative;
    overflow: hidden;
}

.mail__container {
    padding: 200px 10%;
    display: flex;
    z-index: 10;
}
@media (max-width: 998px) {
    .mail__container {
        padding: 200px 15px;
    }
}
@media (max-width: 480px) {
    .mail__container {
        padding: 130px 15px;
    }
}
.mail__visible {
    display: flex;
    z-index: 10;
}

@media (max-width: 998px) {
    .mail__visible {
        flex-direction: column-reverse;
    }
}

.mail__form {
    
    align-self: center;
    padding: 30px 60px;
    flex: 0 0 20%;
    background: #76B9FF;
    border: 3px solid #286FE4;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    box-shadow: -20px -40px 0px 0px rgba(40, 111, 228, 1);

    label {
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #1650B0;
    }

    input,
    textarea {
        width: 100%;
        padding-bottom: 5px;
        background: #76B9FF;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid #286FE4;
        background-color: transparent;
        margin-bottom: 25px;
        color: #0047CA;;
        font-size: 20px;
        &:focus-visible {
            outline: none;
            border-bottom: 3px solid #286FE4;
            color: #0047CA;;
        }
    }

    input:-webkit-autofill {
        transition: all 5000s ease-in-out;
        -webkit-text-fill-color: #0047CA !important;
    }

    textarea {
        border-radius: 10px;
        min-height: 150px;
        border: 3px solid #286FE4;
        resize: none;
        height: 115%;
    }
}
@media (max-width: 998px) {
    .mail__form {
        box-sizing: border-box;
        min-width: 60%;
        max-width: 90%;
        margin-top: 30px;
    }
}

.mail__btn,
.mail__btn:focus {
    min-width: 100%;
    padding: 20px 22px;
    background: #1466FF;
    box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    border-radius: 24px;
    font-size: 20px;
    line-height: 23px;
    font-variant: all-small-caps;
    color: #DAEDFF;
    border: none;
    &:hover {
        background: #185FE3;
        box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    }
    &:active {
        background: #0C52D5;
        box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
    }
    &:disabled {
        cursor: no-drop;
        background: #5C5C5C;
        box-shadow: none;
    }
}
.mail__content {
    padding: 0 10% 10%;
    flex: 1 0 50%
}
@media (max-width: 998px) {
    .mail__content {
       padding: 0;
    }
}
.mail__tittle {
    padding: 5% 0;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #EEF1F5;
}

.mail__text {
    font-weight: 400;
    font-size: 32px;
    line-height: 45px;
    color: #EDF2F5;
}

.mail__img {
    position: absolute;
    top: 80px;
    left: 20%;
    }
@media (max-width: 998px) {
    .mail__img  {
        top: 0;
    }
}
.mail__cloud {
    background-image: url('../../../assets/mail/mainbg2.png');
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 15000px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    overflow: hidden;
}
@media (max-width: 998px) {
    .mail__cloud {
        height: 20%
    }
}
@-webkit-keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

@keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}