.app__container {
    overflow: hidden;
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    *:focus-visible {
        outline: none;
    }
}
.no-scroll {
    overflow: hidden;
}
.app__content {
    flex: 1 0 100%;
    
}

.material-icons {
    cursor: pointer;
    font-size: 30px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.wavesurfer-handle{
    background-color: rgb(255, 255, 255) !important;
  }
