.wrapper {
    padding: 0 10%;
    position: relative;
    z-index: 5;
    overflow: hidden;
}
@media (max-width: 700px) {
    .wrapper {
        padding: 0 10px;
    } 
}
.upperBlock__container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 3;
    max-width: 100%;
    background-image: url(../../../assets/main/bird1.png), url(../../../assets/main/bird2.png), url(../../../assets/main/bird3.png), url(../../../assets/main/bird4.png), url(../../../assets/main/bird5.png);
    background-position: 73% 15%, 75% 80%, 100% 50%, 0% 75%, 0% 15%;
    background-repeat: no-repeat;
    background-size: 25%, 20%, 7%, 8%, 25%;
}
@media (max-width: 700px) {
    .upperBlock__container {
        background-image: url(../../../assets/main/bird2.png),  url(../../../assets/main/bird5.png);
        background-position: 50% 95%, 0% 15%;
        background-size: 30vh, 25vh;
    }
}
@media  (max-width: 1000px) {
    .upperBlock__container {
        background-image: url(../../../assets/main/bird2.png),  url(../../../assets/main/bird5.png);
        background-position: 80% 95%, 0% 15%;
        background-size: 30vh, 25vh;
    }
}
.upperCloud {
    background-image: url('../../../assets/main/mainbg.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 11290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    overflow: hidden;
}

@-webkit-keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

@keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

.tittle {
    color: #1E5ED6;
    height: 100%;
    display: flex;
    padding-left: 15%;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 768px) {
    .tittle{
        padding: 0;
        text-align: center;
    }
}
.tittle__up {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
}
@media (max-width: 700px) {
    .tittle__up {
        font-size: 30px;
        line-height: 35px;
    }
}
.tittle__down {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    color: #1E5ED6;
    
}
@media (max-width: 700px) {
    .tittle__down {
        font-size: 20px;
        line-height: 25px;
        margin: 0
    }
}

.try {
    padding-top: 20px;
}

.button__try {
    padding: 23px 125px;
    background: #1466FF;
    box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    border-radius: 24px;
    font-size: 20px;
    line-height: 23px;
    font-variant: small-caps;
    color: #DAEDFF;
    border: none;
    &:hover {
        background: #185FE3;
        box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    }
    &:active {
        background: #0C52D5;
        box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
    }
    &:disabled {
        background: #5C5C5C;
        box-shadow: none;
    }
    svg {
        padding-left: 10px;
    }
}