.box {
    margin-bottom: 25px;
    background: #0047CA;
    border-radius: 20px;
    position: relative;

}
@media (min-width: 769px) {
    .box {
        display: none;
    }
}
.title {    
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    padding: 10px 20px;
    text-transform: capitalize;
}
.box__img {
    position: relative;
    bottom: -17px;
}
.box__id {
    position: relative;
    display: flex;
    padding: 5px 20px 35px 20px;
    gap: 15px;
    div {
        border-radius: 10px;
        color: #FFFFFF;
        font-weight: 600;
    }
    div:nth-child(2) {
        flex: 1 0 30%;
    }
}
.box__content {
    display: flex;
    gap: 15px;
    div {
        background: rgba(13, 60, 147, 0.82);
        padding: 15px;
        font-size: 15px;
    }
    .content__id {
       width: 100%;
    }
}
.content__title {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    padding: 10px 0;
    text-transform: capitalize;
    //word-wrap: break-word;
    word-break: break-all;
}
.name__content {
    background: #C9DCFF;
    font-weight: 600;
    font-size: 14px;
    color: #0047CA;
    padding: 10px 20px;
}
.arrow__down {
    margin: auto;
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    transition: all 0.3s;
    right: 0;
    left: 0;
    bottom: 10px;
}
.arrow__up {
    margin: auto;
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(225deg);
    transition: all 0.3s;
    right: 15px;
    top: 20px;
}
.content {
    background: #D9E3F8;
    color: #203B7E;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 12px;
    &:last-child {
        border-radius: 0 0 20px 20px;
    }
}