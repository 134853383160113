.body {
  margin-top: 150px;
  display: flex;
  max-width: 100%;
  background: #286FE4;
}

@media (max-width: 991.98px) {
  .body {
    flex-direction: column;
    margin-top: 0;
  }
}

.discription {
  display: flex;
  flex-direction: column;
  padding: 86px 90px 52px 90px;
  flex: 1 0 35%;
  background: #286FE4;
  position: relative;
}

@media (max-width: 991.98px) {
  .discription {
    flex: 1 0 100%;
  }
}

@media (max-width: 479.98px) {
  .discription {
    padding: 40px 20px 25px 20px;
  }
}



.definition {
  font-size: 24px;
  line-height: 150%;
  color: #EDF2F5;
  margin: 0 0 40px 0;
}

.advertising {
  flex: 1 0 55%;
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(../../../assets/Download/happy.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;
}

@media (max-width: 700px) {
  .advertising {
    background-size: cover;
  }
}

.advertising__content {
  width: 100%;
  padding: 15% 52px;

}

@media (max-width: 991.98px) {
  .advertising__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.buttons {
  display: flex;
  gap: 15px;
  align-self: center;
  margin-bottom: 20px;
}

@media (max-width: 479.98px) {
  .buttons {
    margin-bottom: 50px;
  }
}

.star_text {
  position: absolute;
  bottom: 45px;
  color: #FFFFFF;
}

@media (max-width: 991.98px) {
  .star_text {
    bottom: 25px;
  }
}

.title {
  position: relative;
  top: -5px;
  left: -1%;
  font-weight: 600;
  font-size: 85px;
  line-height: 77%;
  writing-mode: vertical-rl;
  padding: 0 0 0 0px;
  background-color: transparent;
  color: #286FE4;
}

@media (max-width: 479.98px) {
  .title {
    font-size: 50px;
    top: -3px;
    left: -5px;
  }
}

.text {
  max-width: 330px;
  font-size: 32px;
  text-transform: lowercase;
  margin: 0 0 27px 0;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .text {
    font-size: 24px;
    line-height: 139%;
  }
}

.button {
  padding: 15px 0;
  width: 150px;
  background: #DAEDFF;
  border-radius: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  border: none;
  color: #1466FF;

  &:hover {
    background: #FFFFFF;
  }

  &:active {
    background: #DAEDFF;
  }
  &:disabled {
    background: #ACB6C9;
  }
}

.animate {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 120px;
  height: 43px;
}

.soundwaves::before,
.soundwaves::after,
.soundwaveone::before,
.soundwaveone::after {
  position: absolute;
  background: white;
  border-radius: 10px;
  width: 3px;
  content: "";
}

.soundwaves {
  display: flex;
  align-items: center;
  width: 30%;
  position: relative;

  &::before {
    left: 0;
    height: 32%;
    -webkit-animation: soundwaves5 0.5s linear infinite;
    animation: soundwaves5 0.5s linear infinite;
  }

  &::after {
    right: 0;
    height: 40%;
    -webkit-animation: soundwaves4 1s ease-in-out infinite;
    animation: soundwaves4 1s ease-in-out infinite;
  }
}

@-webkit-keyframes soundwaves5 {
  0% {
    height: 32%;
  }

  100% {
    height: 60%;
  }
}

@keyframes soundwaves5 {
  0% {
    height: 32%;
  }

  100% {
    height: 60%;
  }
}

@-webkit-keyframes soundwaves4 {
  0% {
    height: 40%;
  }

  25% {
    height: 20%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 0%;
  }

  100% {
    height: 40%;
  }
}

@keyframes soundwaves4 {
  0% {
    height: 40%;
  }

  25% {
    height: 20%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 0%;
  }

  100% {
    height: 40%;
  }
}

.soundwaveone {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 8px;
  height: 100%;
  width: 30%;

  &::before {
    left: 0;
    height: 50%;
    -webkit-animation: soundwaves3 1s ease-in-out infinite;
    animation: soundwaves3 1s ease-in-out infinite;
  }

  &::after {
    right: 0;
    height: 100%;
    -webkit-animation: soundwaves2 2s ease-in-out infinite;
    animation: soundwaves2 2s ease-in-out infinite;
  }
}

@-webkit-keyframes soundwaves3 {
  0% {
    height: 50%;
  }

  100% {
    height: 25%;
  }
}

@keyframes soundwaves3 {
  0% {
    height: 50%;
  }

  100% {
    height: 25%;
  }
}

@-webkit-keyframes soundwaves2 {
  0% {
    height: 100%;
  }

  25% {
    height: 20%;
  }

  50% {
    height: 70%;
  }

  75% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes soundwaves2 {
  0% {
    height: 100%;
  }

  25% {
    height: 20%;
  }

  50% {
    height: 70%;
  }

  75% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

.soundwavetwo {
  height: 70%;
  width: 3px;
  background-color: white;
  margin-left: 5px;
  border-radius: 10px;
  -webkit-animation: soundwaves1 1.5s ease-in-out infinite;
  animation: soundwaves1 1.5s ease-in-out infinite;
}

@-webkit-keyframes soundwaves1 {
  0% {
    height: 70%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 50%;
  }

  75% {
    height: 0%;
  }

  100% {
    height: 70%;
  }
}

@keyframes soundwaves1 {
  0% {
    height: 70%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 50%;
  }

  75% {
    height: 0%;
  }

  100% {
    height: 70%;
  }
}