.resultModule {
    padding-bottom: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .resultModule {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 20px;
    }
}

.recognitionButton {
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    padding: 15px 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: #DAEDFF;
    background: #1466FF;
    box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    border-radius: 30px;

    &:hover {
        background: #185FE3;
    }

    &:active {
        background: #0C52D5;
        box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
    }

    .btnBird {
        margin-left: 15px;
        width: 40px;
        height: 40px;
    }
}

.spectrogram {
    margin-top: 20px;
    display: flex;
    
    text-align: center;
    border-radius: 15px;
    max-width: 100%; 
}
.legend_body {
    img{
        max-height: 500px;
        border-radius: 15px 0 0 15px;
    }
}

.anno_body {
    overflow-x: auto;
    border-radius: 0 15px 15px 0;
    &::-webkit-scrollbar {
        height: 16px;  
        position: absolute;             
      }
    &::-webkit-scrollbar-track {
        border-radius: 20px;
        background: #DAEDFF;;       
      }
    &::-webkit-scrollbar-thumb {
        background-color: #1466FF;  
        border-radius: 20px;      
      }
    img{
        max-height: 500px;
        border-radius: 0 15px 15px 0;
    }    
}
@media screen and (min-width:320px) and (max-width:800px) {
    .anno_body::-webkit-scrollbar {
        height: 25px;              
      }
}
.resultContainer {
    padding: 15px 40px;
    border-radius: 20px;
    margin-top: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 768px) {
    .resultContainer {
        background: none;
        padding: 15px 0;
    }
}

.bird_card {
    box-sizing: border-box;
    padding: 20px;
    width: 400px;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 40px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #1466FF;
    border-radius: 30px;
    display: flex;
    gap: 20px;

    .card_title {
        font-weight: 600;
        font-size: 25px;
        line-height: 29px;
    }

    .segment_title {
        margin-top: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    .segment {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        .segment_time {
            flex: 0 0 50%;
            margin: 5px 0 0 0;
        }
    }

    img {
        max-width: 135px;
        border-radius: 15px;
    }
}

@media (max-width: 768px) {
    .bird_card {
        width: 100%;
    }
}