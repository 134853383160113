.languages_container {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #1466FF;
    position: relative;

}

.selected_language {

    &:hover,
    &:hover svg {
        color: #0047CA;
        fill: #0047CA;
    }
}

.languages {
    position: absolute;
    top: 130%;
    left: -35%;
    background: #FFFFFF;

    div {
        padding: 10px 25px;

        &:hover {
            color: #0047CA;
            ;
        }
    }
}