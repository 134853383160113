.wrapper {
    padding: 0 10%;
    position: relative;
    z-index: 5;
    min-height: 100vh;
}
@media (max-width: 768px) {
    .wrapper {
        padding: 0 15px;;
    }
}
.upperCloud {
    background-image: url('../../assets/main/mainbg.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 45%;
    width: 8290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: 15%;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    overflow: hidden;
}
.uuderCloud {
    background-image: url('../../assets/mail/mainbg2.png');
    position: absolute;
    bottom: 0;
    left: 0;
    height: 400px;
    width: 11290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    animation-direction: reverse;
    overflow: hidden;
}
@-webkit-keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

@keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

.container {
    min-height: 100vh;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    font-size: 25px;
    line-height: 29px;
    a {
        text-decoration: none;
    }
}
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        justify-content: start;
        gap: 30px;
        padding-top: 70px;
    }
}

.about {
    padding: 100px 10% 350px;
    color: #1E5ED6;
    font-family: 'Roboto';
    font-style: normal;
    position: relative;
    z-index: 5;
    overflow: hidden;
}

.about_tittle, 
.services_tittle {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
}

.about__text {
    padding-top: 15px;
    font-weight: 400;
    font-size: 25px;
    line-height: 45px;
}

.task_ul {
    padding: 10px 0;

    li {
        margin-left: 26px;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
    }

    & li::before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #1466FF;
        display: inline-block;
        margin: 0 10px -3px -26px;
    }
}