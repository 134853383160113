.swiper {
    width: 330px;
    height: 100%;
    min-width: 300px;
    max-height: 400px;
    position: absolute;
    left: 60%;
    top: 150px;
    margin-left: -150px;
    margin-top: -150px;
    user-select: none;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
  