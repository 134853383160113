.header__container {
    z-index: 15;
    width: 100%;
    position: absolute;
    top: 0px;
}

.header__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    gap: 15px;
}
@media (max-width: 998px) {
    .header__body {
        padding: 0 10px;
    }
}
.header__logo img {
    padding-top: 15px;
}
@media (max-width: 700px) {
    .header__logo img {
        height: 40px;
    }
}
.header__right_block {
    display: flex;
    align-items: center;
    gap: 35px;
}

@media (max-width: 700px) {
    .header__right_block {
        gap: 10px;
    }
}
.select_language {
    outline: none;
    background: none;
}
.header__button{ 
    display: flex;
    align-items: center;
    button {
    font-variant: small-caps;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    padding: 6px 20px;
    border-radius: 12px;
    }
    span {
        margin-right: 25px;
        font-variant: small-caps;
        font-weight: 600;
        font-size: 25px;
        color: #1466FF
    }
}
@media (max-width: 700px) {
    .header__button {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        button {
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        padding: 5px 10px;
    }
        span {
            font-size: 20px;
            margin-right: 0px;
        }
}
}
.button__up {
    background-color: #1466FF;
    color: #ACD2FE;
    border: 1px solid rgba(0, 0, 0, 0);
    margin-right: 25px;
    transition: .3s;
    &:hover {
        background: #185FE3;
        box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    }
    &:active {
        background: #0C52D5;
        box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
    }
    &:disabled {
        background: #5C5C5C;
        box-shadow: none;
    }
}
@media (max-width: 700px) {
    .button__up {
        margin-right: 0px;
    }
}
.button__in {
    background-color: rgba(217, 230, 255, 0.45);
    color: #1466FF;
    border: 1px solid #1466FF;
    transition: .3s;
    &:hover {
        background: #BCD8FC;
        border: 1px solid #2F75F8;
    }
    &:active {
        background: #67B0FC;
        border: 1px solid #1466FF;
    }
    &:disabled {
        background: #ECECEC;
        border: 1px solid #25282B;
        color: #25282B;
    }
}

