.progress_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.progress_box {
    padding: 0 20px 25px;
    width: 250px;
    height: 250px;
    background: #FFFFFF;
    font-size: 16px;
    color: #368EFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    box-sizing: border-box;
}

.picture {
    flex: 1 0 auto;
}

.progress {
    align-self: end;
    font-weight: 600;
}

.text {
    color: #1466FF;
    font-weight: 600;
}