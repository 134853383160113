
.footer__container {
    font-family: roboto;
    padding: 5% 10%;
    background-color: #286FE4;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    color: #D9D9D9;
    z-index: 3;
}
@media (max-width: 700px) {
    .footer__container {
        flex-direction: column;
        padding: 15px;
        align-items: center;
    }
}
.footer__colomn {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 0 1 20%;
    a {
        color: #D9D9D9;
        text-decoration: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
    }
    a:not([href="##"]):hover {
        color: #FFFFFF;
    }
}
@media (max-width: 700px) {
    .footer__colomn a {
        align-self: center;
    }
}
.footer__tittle {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 15px;
    text-transform: uppercase;
}

@media (max-width: 700px) {
    .footer__tittle {
        align-self: center;;
    }
}
.follow__img {
    margin-bottom: 10%;
    svg {
        cursor: pointer;
        fill: #D9D9D9;
        &:hover {
            fill: #FFFFFF;
        }
    }
    svg:not(:last-child) {
        padding-right: 20px;
    }
}
