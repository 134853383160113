.wrapper {
    padding: 0 10%;
    position: relative;
    z-index: 5;
    height: 100vh;
}
@media (max-width: 768px) {
    .wrapper {
        padding: 0 15px;
    }
}
.upperCloud {
    background-image: url('../../assets/main/mainbg.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 95%;
    width: 8290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    overflow: hidden;
}
.uuderCloud {
    background-image: url('../../assets/mail/mainbg2.png');
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25%;
    width: 11290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    animation-direction: reverse;
    overflow: hidden;
}
@-webkit-keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

@keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}
.loader {
    height: 100%;
}
.container {
    padding-top: 7%;
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preloader_body {
    margin-top: -14%;
    display: flex;
    justify-content: center;
    align-items: center;
    .preloader {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}