.wrapper {
    padding: 0 10%;
    position: relative;
    z-index: 5;
    min-height: 100vh;
}
@media (max-width: 768px) {
    .wrapper {
        padding: 0 15px;
    }
}
.upperCloud {
    background-image: url('../../assets/main/mainbg.png');
    position: absolute;
    top: 0;
    left: 0;
    height: 45%;
    width: 8290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: 15%;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    overflow: hidden;
}
.uuderCloud {
    background-image: url('../../assets/mail/mainbg2.png');
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 11290px;
    z-index: 0;
    background-repeat: repeat-x;
    background-size: contain;
    -webkit-animation: movesky 900s linear infinite;
    animation: movesky 900s linear infinite;
    animation-direction: reverse;
    overflow: hidden;
}
@-webkit-keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

@keyframes movesky {
    from {
        transform: translate(0);
    }

    to {
        transform: translate(-5645px);
    }
}

.container {
    padding-top: 100px;
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column-reverse;
        align-items: center;
        padding-bottom: 15px;
    }
}
.form_container {
    padding-top: 100px;
    flex: 0 1 30%;
    display: flex;
    flex-direction: column;
    color: #0047CA;
}
@media (max-width: 768px) {
    .form_container {
        padding-top: 15px;
        min-width: 290px;
        width: 100%;
        max-width: 530px;
    }
}
.form {
    label {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        font-variant: small-caps;
    }
    input {
        width: 100%;
        background: #76B9FF;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 4px solid #1466FF;
        background-color: transparent;
        margin-bottom: 45px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: #1466FF;
        &:-webkit-autofill {
            -webkit-text-fill-color: #1466FF !important;
            transition: all 5000s ease-in-out;
        }
        &:focus-visible {
            outline: none;
        }
    }
} 

.signBtn {
    margin-top: 20px;
    min-width: 100%;
    padding: 23px 75px;
    background: #1466FF;
    box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    border-radius: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    font-variant: small-caps;
    color: #DAEDFF;
    &:hover {
        background: #185FE3;
        box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    }
    &:active {
        background: #0C52D5;
        box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
    }
    &:disabled {
        cursor:no-drop;
        background: #5C5C5C;
        box-shadow: none;
    }
    &:focus-visible {
        outline: none;
    }
}

.inputField {
    display: flex;
    position: relative;
}
.vis {
    position: absolute;
    right: 5px;
    cursor: pointer;
}

.profile_container{
    padding-top: 100px;
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile_content {
    position: relative;
}
.main_photo {
    max-width: 330px;
    border-radius: 85px 85px 0px 0px;
    box-shadow: 17px -19px 0px 0px rgba(40, 111, 228, 1);
}

.upper_bird {
    position: absolute;
    top: -120px;
    right: 0px;
}

.photo_set {
    width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
}

.little_photo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.little_text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #0047CA;
}

.button__up {
    cursor: pointer;
    text-align: center;
    background-color: #1466FF;
    color: #ACD2FE;
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 5px 10px;
    border-radius: 15px;
    transition: .3s;
    &:hover {
        background: #185FE3;
        box-shadow: 0px 3px 10px rgba(7, 62, 164, 0.53);
    }
    &:active {
        background: #0C52D5;
        box-shadow: inset 2px 7px 10px rgba(0, 0, 0, 0.25);
    }
    &:disabled {
        background: #5C5C5C;
        box-shadow: none;
    }
}

.button__in {
    cursor: pointer;
    text-align: center;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: rgba(217, 230, 255, 0.45);
    color: #1466FF;
    border: 1px solid #1466FF;
    transition: .3s;
    &:hover {
        background: #BCD8FC;
        border: 1px solid #2F75F8;
    }
    &:active {
        background: #67B0FC;
        border: 1px solid #1466FF;
    }
    &:disabled {
        background: #ECECEC;
        border: 1px solid #25282B;
        color: #25282B;
    }
}