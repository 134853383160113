.about {
    padding: 0 10%;
    color: #EDF2F5;
    font-family: 'Roboto';
    font-style: normal;
    position: relative;
    z-index: 5;
    overflow: hidden;
}

.about_tittle, 
.services_tittle {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
}

.about__text {
    padding-left: 100px;
    padding-top: 15px;
    font-weight: 400;
    font-size: 25px;
    line-height: 45px;
}
@media (max-width: 990px) {
    .about__text {
        padding-left: 0;
    }
}
.task_container {
    padding-top: 30px;
    display: flex;
    gap: 50px;

    .task_img {
        margin-top: 80px;

        img {
            border-radius: 50px;
            box-shadow: -20px -20px 0px 0px rgba(40, 111, 228, 1);
        }
    }

    .task_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
    }

    .task_list p {
        font-weight: 300;
        font-size: 20px;
        line-height: 45px;
    }

    .task_ul {
        padding: 10px 0;

        li {
            margin-left: 26px;
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
        }

        & li::before {
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #1466FF;
            display: inline-block;
            margin: 0 10px -3px -26px;
        }
    }
}

@media (max-width: 992px) {
    .task_container {
        flex-direction: column;
        align-items: center;
        .task_img {
            margin-top: 40px;
            
        }
    }
}
.services_tittle {
    padding-top: 50px;

}
.about__services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.service__card {
    display: flex;
    flex: 1 0 33.333%;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    padding: 15px;
}

.card__img img {
    height: 150px;
}

.card__text {
    padding-top: 10px;
    font-size: 24px;
    text-align: center;
    width: 300px;
}