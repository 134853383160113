.checkboxContainer {
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #f3f7ff;
    padding: 10px;
    border-radius: 10px;
    width: 150px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.checkboxContainer label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #3578e5;
    margin-bottom: 10px;
    cursor: pointer;
}

.checkboxContainer input[type='checkbox'] {
    accent-color: #3578e5;
    margin-right: 10px;
}