.exaples_body {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;

}

.examples_header {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    color: #1466FF;
    background-color: #96BDFF;
    padding: 10px 30px;
    border-radius: 50px;
}

.examples_items {
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    background-color: #4F89F6;
    color: #FFFFFF;
    border-radius: 10px;
}

.example_item {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    &:nth-child(2) {
        border-top: 2px solid #FFFFFF;
        border-bottom: 2px solid #FFFFFF;
    }
}

.circle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid #1466FF;
    background-color: #FFFFFF;
    position: relative;
}
.circle::before {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    top: 1px;
    left: 1px;
    border-radius: 50%;
}

.example_item:hover .circle::before,
.circle.active::before {
    background-color: #1466FF;
}